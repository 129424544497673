import { template as template_21e160bd273c4004a982388019e152e9 } from "@ember/template-compiler";
const SidebarSectionMessage = template_21e160bd273c4004a982388019e152e9(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
