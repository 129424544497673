import { template as template_03f514c8e98949339dee386393edad7e } from "@ember/template-compiler";
const FKText = template_03f514c8e98949339dee386393edad7e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
