import { template as template_38e085e101dc486a83df44cfa703b6ee } from "@ember/template-compiler";
const FKControlMenuContainer = template_38e085e101dc486a83df44cfa703b6ee(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
